<template>
    <div>index</div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>