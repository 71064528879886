import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Token from './js/utils/token'

Vue.config.productionTip = false
import './style/app.css'
import 'vant/lib/index.css';
// import Vconsole from 'vconsole';
// new Vconsole();

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.query.token) {
    Token.setValue(to.query.token)
  }
  next();
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
