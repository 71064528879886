import Vue from 'vue'
import VueRouter from 'vue-router'
import Indexpage from '../pages/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Indexpage
  },
  {
    path: '/visit',
    name: 'Visit',
    component: () => import('../pages/visit.vue'),
    meta: {
      title: '联仁健康智慧云平台',
      keepalive: true
    }
  },
  {
    path: '/creatQuery',
    name: 'CreatQuery',
    component: () => import('../pages/creat-query.vue'),
    meta: {
      title: '联仁健康智慧云平台',
      keepalive: false
    }
  },
  {
    path: '/queryDetail',
    name: 'QueryDetail',
    component: () => import('../pages/query-detail.vue'),
    meta: {
      title: '联仁健康智慧云平台',
      keepalive: false
    }
  },
  {
    path: '/queryList',
    name: 'QueryList',
    component: () => import('../pages/query-list.vue'),
    meta: {
      title: '联仁健康智慧云平台',
      keepalive: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
